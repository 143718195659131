import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { sendAllMemberNotification } from "../../controller/NotificationController";

const CreatNotification = () => {
  const navigate = useNavigate();

  const [title, setTitle] = React.useState("");
  const [body, setBody] = React.useState("");

  return (
    <Container>
      <Header />
      <Box
        mb={5}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight={"700"}>
          푸시알림 전송페이지
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1.5,
          }}
        >
          <Button
            variant="contained"
            onClick={async () => {
              if (window.confirm("전송하시겠습니까?")) {
                await sendAllMemberNotification(title, body);
              }
            }}
          >
            전송
          </Button>
        </Box>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={title}
          label="제목을 입력하세요"
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          variant="outlined"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={body}
          label="내용을 입력하세요"
          onChange={(e) => setBody(e.target.value)}
        />
      </Box>
    </Container>
  );
};

export default CreatNotification;
