import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import { useQuery } from "@tanstack/react-query";
import { posterListController } from "../../controller/PosterController";
import { useNavigate } from "react-router-dom";

const PosterList = () => {
  const navigate = useNavigate();

  const [status, setStatus] = React.useState("ACTIVE");
  const [page, setPage] = React.useState(0);
  const [maxPerPage, setMaxPerPage] = React.useState(100);

  const { data } = useQuery({
    queryKey: ["poster_list", status],
    queryFn: () => posterListController(page, maxPerPage, status),
  });

  const handleChange = (event) => {
    setStatus(event.target.value);
    console.log(event.target.value);
  };

  return (
    <Container>
      <Header />
      <Box
        mb={5}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" fontWeight={"700"}>
          포스터 리스트 페이지
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={() => navigate("/poster_detail")}
          >
            포스터 등록
          </Button>
          <FormControl>
            <InputLabel id="demo-simple-select-label">상태</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="승인완료"
              onChange={handleChange}
            >
              <MenuItem value={"WAIT"}>승인대기</MenuItem>
              <MenuItem value={"ACTIVE"}>승인완료</MenuItem>
              <MenuItem value={"END"}>기한만료</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Grid
        container
        py={1.5}
        px={1}
        sx={{
          backgroundColor: "silver",
        }}
      >
        <Grid item xl={4} md={4}>
          제목
        </Grid>

        <Grid item xl={2} md={2}>
          장르
        </Grid>
        <Grid item xl={2} md={2}>
          무드
        </Grid>
        <Grid item xl={4} md={4}>
          장소
        </Grid>
      </Grid>
      {data &&
        data?.posters &&
        data?.posters?.map((posterEl, index) => (
          <Grid
            container
            py={1.5}
            px={1}
            key={`${JSON.stringify(posterEl.posterId)}_${index}`}
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid silver",
              "&: hover": {
                backgroundColor: "gray",
              },
            }}
            onClick={() =>
              navigate(`/poster_detail/${posterEl.posterId}?status=${status}`)
            }
          >
            <Grid item xl={4} md={4}>
              {posterEl.eventName}
            </Grid>

            <Grid item xl={2} md={2}>
              {posterEl.genre}
            </Grid>
            <Grid item xl={2} md={2}>
              {posterEl.mood}
            </Grid>
            <Grid item xl={4} md={4}>
              {posterEl.place.address}
            </Grid>
          </Grid>
        ))}
    </Container>
  );
};

export default PosterList;
