import React from "react";
import { bannerListController } from "../../controller/BannerController";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Pagination from "../../components/Pagination";

const BannerList = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = React.useState(0);

  const { data } = useQuery({
    queryKey: ["banner_list"],
    queryFn: () => {
      return bannerListController();
    },
  });

  return (
    <Container>
      <Header />
      <Box
        mb={5}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight={"700"}>
          배너 리스트 페이지
        </Typography>
        <Button variant="contained" onClick={() => navigate("/banner_detail")}>
          배너 등록
        </Button>
      </Box>
      <Grid
        container
        py={1.5}
        px={1}
        sx={{
          backgroundColor: "silver",
        }}
      >
        <Grid item xl={6} md={6}>
          이름
        </Grid>
        <Grid item xl={6} md={6}>
          내용
        </Grid>
      </Grid>

      {data &&
        data?.map((bannerEl, index) => (
          <Grid
            container
            py={1.5}
            px={1}
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid silver",
              "&: hover": {
                backgroundColor: "gray",
              },
            }}
            key={bannerEl.bannerId}
            onClick={() =>
              navigate(
                `/banner_detail/${bannerEl.bannerId}?data=${JSON.stringify(
                  bannerEl
                )}`
              )
            }
          >
            <Grid item xl={6} md={6}>
              {bannerEl.title}
            </Grid>
            <Grid item xl={6} md={6}>
              {bannerEl.content}
            </Grid>
          </Grid>
        ))}
    </Container>
  );
};

export default BannerList;
