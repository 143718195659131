import { Box, Typography } from "@mui/material";
import React from "react";

const Pagination = ({
  totalPage,
  currentPage,
  onPageChange,
  setCurrentPage,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button
        onClick={async () => {
          if (currentPage > 0) {
            await onPageChange();
            setCurrentPage((prev) => prev - 1);
          } else {
            alert("첫 페이지입니다.");
          }
        }}
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      <Typography style={{ marginRight: 10, marginLeft: 10 }} variant={"h6"}>
        {currentPage}
      </Typography>
      <button
        onClick={async () => {
          if (totalPage > currentPage) {
            await onPageChange();
            setCurrentPage((prev) => prev + 1);
          } else {
            alert("마지막 페이지입니다.");
          }
        }}
        disabled={currentPage === totalPage}
      >
        &gt;
      </button>
    </Box>
  );
};

export default Pagination;
