import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { categoryListController } from "../../controller/CategoryController";

const CategoryList = () => {
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["category_list"],
    queryFn: () => {
      return categoryListController();
    },
  });

  return (
    <Container>
      <Header />
      <Box
        mb={5}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight={"700"}>
          카테고리 리스트 페이지
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/category_detail")}
        >
          카테고리 등록
        </Button>
      </Box>
      <Grid
        container
        py={1.5}
        px={1}
        sx={{
          backgroundColor: "silver",
        }}
      >
        <Grid item xl={6} md={6}>
          제목
        </Grid>
        <Grid item xl={6} md={6}>
          연결목록
        </Grid>
      </Grid>
      {data &&
        data.categories?.map((categoryEl, index) => (
          <Grid
            container
            py={1.5}
            px={1}
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid silver",
              "&: hover": {
                backgroundColor: "gray",
              },
            }}
            key={categoryEl.categoryId}
            onClick={() =>
              navigate(
                `/category_detail/${
                  categoryEl.categoryId
                }?data=${JSON.stringify(categoryEl)}`
              )
            }
          >
            <Grid item xl={6} md={6}>
              {categoryEl.categoryName}
            </Grid>
            <Grid item xl={6} md={6}>
              {categoryEl.simplePosters.map((el) => {
                return `${el.eventName}/ `;
              })}
            </Grid>
          </Grid>
        ))}
    </Container>
  );
};

export default CategoryList;
