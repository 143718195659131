import { createTheme } from "@mui/material/styles";

const breakPoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    xxl: 2000, // 새로운 Breakpoint 추가
  },
};

const theme = createTheme({
  breakPoints,
});

export default theme;
