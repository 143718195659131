import { Box, Button } from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import styled from "styled-components";

const buttons = [
  { path: "/", label: "대시보드" },
  { path: "/user_list", label: "유저리스트" },
  { path: "/poster_list", label: "포스터리스트" },
  { path: "/banner_list", label: "배너" },
  { path: "/category_list", label: "카테고리" },
  { path: "/popup_list", label: "팝업" },
  { path: "/notification", label: "푸시알림" },
  { path: "/notice_list", label: "공지" },
];

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleButtonClick = (path) => {
    // 클릭된 버튼의 경로 저장
    navigate(path);
  };

  return (
    <Box
      my={1}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {buttons.map(({ path, label }) => (
        <LinkBtn
          key={path}
          sx={{
            backgroundColor: location.pathname === path ? "blue" : "red",
            borderRadius: 2.5,
            color: "#fff",
            fontWeight: "700",
            cursor: "pointer",
          }}
          py={1.5}
          px={2}
          onClick={() => handleButtonClick(path)}
        >
          {label}
        </LinkBtn>
      ))}

      <Button
        variant={"outlined"}
        onClick={() => {
          navigate("/login");
          localStorage.clear();
        }}
      >
        로그아웃
      </Button>
    </Box>
  );
};

export default Header;

const LinkBtn = styled(Box)`
  background-color: red;
`;
