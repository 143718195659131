import { ThemeProvider } from "@mui/material";
import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import "./App.css";
import { getRefreshToken } from "./controller/AuthController";
import SignIn from "./pages/Auth/SignIn";
import SignUp from "./pages/Auth/SignUp";
import BannerDetail from "./pages/Banner/BannerDetail";
import BannerList from "./pages/Banner/BannerList";
import CategoryDetail from "./pages/Category/CategoryDetail";
import CategoryList from "./pages/Category/CategoryList";
import Home from "./pages/Home/Home";
import NoticeDetail from "./pages/Notice/NoticeDetail";
import NoticeList from "./pages/Notice/NoticeList";
import PaymentHistoryDetail from "./pages/Payments/PaymentHistoryDetail";
import PaymentHistoryList from "./pages/Payments/PaymentHistoryList";
import PopupDetail from "./pages/Popup/PopupDetail";
import PopupList from "./pages/Popup/PopupList";
import PosterDetail from "./pages/Poster/PosterDetail";
import PosterList from "./pages/Poster/PosterList";
import UserDetail from "./pages/User/UserDetail";
import UserList from "./pages/User/UserList";
import { isLoggedInState } from "./recoil/loginStorage";
import theme from "./theme";
import CreatNotification from "./pages/Notification/CreatNotification";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);

  const access = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const expired = localStorage.getItem("tokenExpiry");
  const expiredRefreshToken = localStorage.getItem("refreshTokenExpiry");

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (access) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    setIsLoading(false);

    const checkToken = async () => {
      if (access && expired && Date.now() < expired) {
        // Access token이 유효한 경우
        setIsLoggedIn(true);
      } else if (
        refreshToken &&
        expiredRefreshToken &&
        Date.now() < expiredRefreshToken
      ) {
        // Refresh token이 유효한 경우, access token 갱신
        const newAccessToken = await getRefreshToken();
        if (newAccessToken.code === 620) {
          const expired = Date.now() + 24 * 60 * 60 * 1000;
          localStorage.setItem("tokenExpiry", expired);
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    checkToken();
  }, [setIsLoggedIn, access, refreshToken]);

  if (isLoading) {
    return <div>Loading...</div>; // 로딩 중일 때 보여줄 컴포넌트
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route
              path="/"
              element={isLoggedIn ? <Home /> : <Navigate to={"/login"} />}
            />
            <Route path="/login" element={<SignIn />} />
            <Route path="/sign_up" element={<SignUp />} />
            <Route
              path="/user_list"
              element={isLoggedIn ? <UserList /> : <Navigate to={"/login"} />}
            />
            <Route
              path="/user_detail/:id"
              element={isLoggedIn ? <UserDetail /> : <Navigate to={"/login"} />}
            />
            <Route
              path="/poster_list"
              element={isLoggedIn ? <PosterList /> : <Navigate to={"/login"} />}
            />
            <Route
              path="/poster_detail/:id?"
              element={
                isLoggedIn ? <PosterDetail /> : <Navigate to={"/login"} />
              }
            />
            <Route
              path="/payment_history"
              element={
                isLoggedIn ? <PaymentHistoryList /> : <Navigate to={"/login"} />
              }
            />
            <Route
              path="/payment_history_detail"
              element={
                isLoggedIn ? (
                  <PaymentHistoryDetail />
                ) : (
                  <Navigate to={"/login"} />
                )
              }
            />
            <Route
              path="/banner_list"
              element={isLoggedIn ? <BannerList /> : <Navigate to={"/login"} />}
            />
            <Route
              path="/banner_detail/:id?"
              element={
                isLoggedIn ? <BannerDetail /> : <Navigate to={"/login"} />
              }
            />
            <Route
              path="/category_list"
              element={
                isLoggedIn ? <CategoryList /> : <Navigate to={"/login"} />
              }
            />
            <Route
              path="/category_detail/:id?"
              element={
                isLoggedIn ? <CategoryDetail /> : <Navigate to={"/login"} />
              }
            />
            <Route
              path="/popup_list"
              element={isLoggedIn ? <PopupList /> : <Navigate to={"/login"} />}
            />
            <Route
              path="/popup_detail/:id?"
              element={
                isLoggedIn ? <PopupDetail /> : <Navigate to={"/login"} />
              }
            />
            <Route
              path="/notice_list"
              element={isLoggedIn ? <NoticeList /> : <Navigate to={"/login"} />}
            />
            <Route
              path="/notice_detail/:id?"
              element={
                isLoggedIn ? <NoticeDetail /> : <Navigate to={"/login"} />
              }
            />
            <Route
              path="/notification"
              element={
                isLoggedIn ? <CreatNotification /> : <Navigate to={"/login"} />
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
