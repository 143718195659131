export const getAddressController = async (page, text) => {
  const callbackName = "callback";
  const response = await new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = `https://api.vworld.kr/req/search?service=search&request=search&version=2.0&query=${text}&type=address&category=road&format=json&errorFormat=json&callback=${callbackName}&page=${page}&size=20&key=${process.env.REACT_APP_VWORLD_KEY}`;

    window[callbackName] = (data) => {
      resolve(data);
      document.body.removeChild(script);
      delete window[callbackName];
    };

    script.onerror = () => {
      reject(new Error("JSONP request failed"));
      document.body.removeChild(script);
      delete window[callbackName];
    };

    document.body.appendChild(script);
  });

  return response.response;
};
