import { Container } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import { paymentHistoryController } from "../../controller/PaymentController";

const PaymentHistoryList = () => {
  React.useEffect(() => {
    paymentHistoryController(1, 0, 10);
  }, []);

  return (
    <Container>
      <Header />
    </Container>
  );
};

export default PaymentHistoryList;
