import axios from "axios";
import { getToken, refreshGetToken } from "../utils/localStorage";

export const signInHandler = async (email, password, handleLogin) => {
  const endPoint = "/api/admins/login";

  try {
    const response = await axios.post(endPoint, {
      email: email,
      password: password,
    });

    const token = response.data.result.token.accessToken;
    const refreshToken = response.data.result.token.refreshToken;

    const expired = Date.now() + 24 * 60 * 60 * 1000;
    const expiredRefreshToken = Date.now() + 14 * 24 * 60 * 60 * 1000;

    localStorage.setItem("tokenExpiry", expired);
    localStorage.setItem("refreshTokenExpiry", expiredRefreshToken);
    localStorage.setItem("accessToken", token);
    localStorage.setItem("refreshToken", refreshToken);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getAdminAccess = async () => {
  const endPoint = "/api/members/admin";
  const token = getToken();

  const security =
    "jsHidcqd8ZPr0JqyETHpkqfjpBf6eKhGDRiDgEh6tXx3PDEvjSfmeualm7FNsJeKRvoqUSbkZm0qXyIVCDK7nQ==";

  try {
    const response = await axios.post(
      endPoint,
      { securityCode: security },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (err) {
    throw err;
  }
};

export const updateProfileController = async (
  password,
  userName,
  birth,
  gender,
  phoneNumber
) => {
  const endPoint = "/api/members/modify";
  const token = getToken();

  try {
    const response = await axios.post(
      endPoint,
      { password, userName, birth, gender, phoneNumber },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const checkTokenExpired = async () => {
  const endPoint = "/api/members/check-token";
  const token = getToken();

  try {
    const response = await axios.post(endPoint, {
      token,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      console.log("Error Response Data:", error.response.data);
    } else {
      console.log("Error:", error.message);
    }
    throw error;
  }
};

export const getRefreshToken = async () => {
  const endPoint = "/members/check-refresh";
  const refreshToken = refreshGetToken();

  try {
    if (refreshToken) {
      const response = await axios.post(endPoint, {
        refreshToken,
      });
      const expired = Date.now() + 24 * 60 * 60 * 1000;
      localStorage.setItem("tokenExpiry", expired);

      return response.data;
    } else {
      return;
    }
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};
