import { Box, Button, Container, Input, TextField } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { signInHandler } from "../../controller/AuthController";
import { useMutation } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { isLoggedInState } from "../../recoil/loginStorage";

const SignIn = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const mutation = useMutation({
    mutationFn: () => signInHandler(email, password),
    onSuccess: (data) => {
      setIsLoggedIn(true);
      navigate("/");
    },
    onError: (err) => {
      alert(err.message);
    },
  });

  React.useEffect(() => {
    // 현재 상태를 추가하여 뒤로가기 비활성화
    window.history.pushState(null, "", window.location.href);

    const handlePopState = () => {
      // 뒤로가기 시도 시 현재 페이지로 다시 이동
      window.history.pushState(null, "", window.location.href);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <SingInContainer>
      <Box
        sx={{
          width: "35%",
          height: "50%",
          border: "2px solid #1976D3",
          borderRadius: 10,
          padding: 6,
          flexDirection: "column",
        }}
      >
        <h1>로그인</h1>
        <span></span>
        <TextField
          variant="outlined"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          value={email}
          label="이메일 입력"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          placeholder="비밀번호를 입력해주세요"
          variant="outlined"
          sx={{
            width: "100%",
          }}
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          label="비밀번호 입력"
        />
        <Button
          onClick={() => mutation.mutate()}
          variant="contained"
          sx={{
            width: "100%",
            marginTop: 1.5,
            paddingTop: 1.5,
            paddingBottom: 1.5,
            fontSize: 15,
            fontWeight: "700",
          }}
        >
          로그인하기
        </Button>
        <Button
          onClick={() => navigate("signUp")}
          variant="contained"
          sx={{
            width: "100%",
            marginTop: 1.5,
            paddingTop: 1.5,
            paddingBottom: 1.5,
            fontSize: 15,
            fontWeight: "700",
          }}
        >
          회원가입
        </Button>
      </Box>
    </SingInContainer>
  );
};

export default SignIn;

const SingInContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const category = styled.span``;
