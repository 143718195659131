import axios from "axios";
import { getToken } from "../utils/localStorage";

export const getNoticeController = async (page, size) => {
  const endPoint = `/api/notices?page=${page}&size=${size}`;
  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.result;
  } catch (err) {
    console.error("Image upload error:", err);
    throw err;
  }
};
export const getSpecificNoticeController = async (id) => {
  const endPoint = `/api/notices/${id}`;
  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error("Image upload error:", err);
    throw err;
  }
};

export const createNoticeController = async (title, content) => {
  const endPoint = `/api/admins/notices`;
  const token = getToken();

  try {
    const response = await axios.post(
      endPoint,
      { title, content },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error("Image upload error:", err);
    throw err;
  }
};

export const updateNoticeController = async (id, title, content) => {
  const endPoint = `/api/admins/notices/${id}`;
  const token = getToken();

  try {
    const response = await axios.post(
      endPoint,
      { title, content },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error("Image upload error:", err);
    throw err;
  }
};

export const deleteNoticeController = async (id) => {
  const endPoint = `/api/admins/notices/${id}`;
  const token = getToken();

  try {
    const response = await axios.delete(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error("Image upload error:", err);
    throw err;
  }
};
