import axios from "axios";
import { getToken } from "../utils/localStorage";

export const categoryListController = async () => {
  const endPoint = `/api/categories`;

  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data.result);
    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const categoryUpdateController = async (data, id) => {
  const endPoint = id
    ? `/api/admins/categories/${id}`
    : `/api/admins/categories`;

  const token = getToken();

  try {
    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    alert(response.data.message);
    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const categoryDeleteController = async (id) => {
  const endPoint = `/api/admins/categories/${id}`;

  const token = getToken();

  try {
    const response = await axios.delete(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    alert(response.data.message);
    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
