import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { popUpListController } from "../../controller/PopUpController";

const PopList = () => {
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["popup_list"],
    queryFn: () => {
      return popUpListController();
    },
  });

  return (
    <Container>
      <Header />
      <Box
        mb={5}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight={"700"}>
          팝업 리스트 페이지
        </Typography>
        <Button variant="contained" onClick={() => navigate("/popup_detail")}>
          팝업 등록
        </Button>
      </Box>
      <Grid
        container
        py={1.5}
        px={1}
        sx={{
          backgroundColor: "silver",
        }}
      >
        <Grid item xl={3} md={6}>
          사진
        </Grid>
        <Grid item xl={6} md={6}>
          포스터 아이디
        </Grid>
      </Grid>

      {data &&
        data?.popups.map((popupEl, index) => (
          <Grid
            container
            py={1.5}
            px={1}
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid silver",
              "&: hover": {
                backgroundColor: "gray",
              },
            }}
            key={popupEl.bannerId}
            onClick={() =>
              navigate(
                `/popup_detail/${popupEl.popupId}?data=${JSON.stringify(
                  popupEl
                )}`
              )
            }
          >
            <Grid item xl={3} md={6}>
              <img src={popupEl.imageUrl} width={200} height={200} />
            </Grid>
            <Grid item xl={6} md={6}>
              {popupEl.posterId}
            </Grid>
          </Grid>
        ))}
    </Container>
  );
};

export default PopList;
