import axios from "axios";
import { getToken } from "../utils/localStorage";

export const popUpListController = async () => {
  const endPoint = `/api/popups`;

  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data.result);
    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const popUpUpdateController = async (data, id) => {
  const endPoint = id ? `/api/admins/popups/${id}` : `/api/admins/popups`;

  const token = getToken();

  try {
    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    alert(response.data.message);
    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const popUpDeleteController = async (id) => {
  const endPoint = `/api/admins/popups/${id}`;

  const token = getToken();

  try {
    const response = await axios.delete(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    alert(response.data.message);
    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
