import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import {
  createNoticeController,
  deleteNoticeController,
  getSpecificNoticeController,
  updateNoticeController,
} from "../../controller/NoticeController";
import { useQueryClient } from "@tanstack/react-query";

const NoticeDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [noticeTitle, setNoticeTitle] = React.useState("");
  const [noticeContent, setNoticeContent] = React.useState("");

  const { id } = useParams();

  React.useEffect(() => {
    const controllerStart = async () => {
      if (id !== undefined) {
        const response = await getSpecificNoticeController(id);
        setNoticeTitle(response.result.title);
        setNoticeContent(response.result.content);
      }
    };
    controllerStart();
  }, [id]);

  return (
    <Container>
      <Header />
      <Box
        mb={5}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight={"700"}>
          카테고리 상세 / 수정 / 등록 페이지
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1.5,
          }}
        >
          <Button
            variant="contained"
            onClick={async () => {
              if (id) {
                const response = await updateNoticeController(
                  id,
                  noticeTitle,
                  noticeContent
                );
                if (response.code === 7035) {
                  navigate(-1);
                }
              } else {
                const response = await createNoticeController(
                  noticeTitle,
                  noticeContent
                );
                if (response.code === 7033) {
                  navigate(-1);
                }
              }
            }}
          >
            등록 / 수정
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              deleteNoticeController(id);
              queryClient.invalidateQueries("notice_list");
              navigate(-1);
            }}
          >
            삭제
          </Button>
        </Box>
      </Box>
      <TextField
        variant="outlined"
        sx={{
          width: "100%",
          marginBottom: 1.5,
        }}
        InputLabelProps={{}}
        value={noticeTitle}
        label="제목을 입력하세요"
        onChange={(e) => setNoticeTitle(e.target.value)}
      />
      <TextField
        variant="outlined"
        sx={{
          width: "100%",
          marginBottom: 1.5,
        }}
        multiline={true}
        rows={30}
        InputLabelProps={{}}
        value={noticeContent}
        label="내용을 입력하세요"
        onChange={(e) => setNoticeContent(e.target.value)}
      />
    </Container>
  );
};

export default NoticeDetail;
