export const getToken = () => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    return token;
  } else {
    return undefined;
  }
};
export const refreshGetToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    return refreshToken;
  } else {
    return undefined;
  }
};
