import axios from "axios";
import { getToken } from "../utils/localStorage";

export const paymentHistoryController = async (id, page, size) => {
  const endPoint = `/api/admins/${id}?page=${page}&size=${size}`;

  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
