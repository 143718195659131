import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getNoticeController } from "../../controller/NoticeController";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";

const NoticeList = () => {
  const navigate = useNavigate();
  const { data: noticeLists } = useQuery({
    queryKey: ["notice_list"],
    queryFn: () => getNoticeController(0, 100),
  });

  return (
    <Container>
      <Header />
      <Box
        mb={5}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight={"700"}>
          공지 리스트 페이지
        </Typography>
        <Button variant="contained" onClick={() => navigate("/notice_detail")}>
          공지 등록
        </Button>
      </Box>
      <Grid
        container
        py={1.5}
        px={1}
        sx={{
          backgroundColor: "silver",
        }}
      >
        <Grid item xl={6} md={6}>
          제목
        </Grid>
        <Grid item xl={6} md={6}>
          생성일
        </Grid>
      </Grid>
      {noticeLists !== undefined &&
        noticeLists.simpleNotices !== undefined &&
        noticeLists.simpleNotices.length > 0 &&
        noticeLists.simpleNotices?.map((noticeEl, index) => (
          <Grid
            container
            py={1.5}
            px={1}
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid silver",
              "&: hover": {
                backgroundColor: "gray",
              },
            }}
            key={noticeEl.noticeId}
            onClick={() => navigate(`/notice_detail/${noticeEl.noticeId}`)}
          >
            <Grid item xl={6} md={6}>
              {noticeEl.title}
            </Grid>
            <Grid item xl={6} md={6}>
              {noticeEl.createdAt}
            </Grid>
          </Grid>
        ))}
    </Container>
  );
};

export default NoticeList;
