import { Button, Container, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { deleteUserController } from "../../controller/UserController";

const UserDetail = () => {
  const { id } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const userData = JSON.parse(queryParams.get("data"));

  // const [email, setEmail] = React.useState("");
  // const [userName, setUserName] = React.useState("");
  // const [gender, setGender] = React.useState("");
  // const [phoneNumber, setPhoneNumber] = React.useState("");

  const [profileImg, setProfileImg] = React.useState("");

  console.log(userData);

  React.useEffect(() => {
    if (userData !== undefined) {
      const profileImageUrl = userData.profileImage.startsWith("http://")
        ? userData.profileImage.replace("http", "https")
        : userData.profileImage;

      setProfileImg(profileImageUrl);
    }
  }, [userData]);

  return (
    <Container>
      <Header />

      <Typography fontWeight={"700"} mb={3} mt={3} variant="h5">
        유저 정보
      </Typography>
      <Typography fontWeight={"500"} mb={1.5} variant="h6">
        프로필 사진
      </Typography>
      {userData.profileImage.length > 0 ? (
        <img src={profileImg} width={100} height={100} />
      ) : (
        <Typography fontWeight={"500"} mb={2} variant="h6" color={"red"}>
          등록된 프로필 사진이 없습니다.
        </Typography>
      )}
      <Typography fontWeight={"500"} mb={1.5} variant="h6">
        권한: {userData.role}
      </Typography>
      <Typography fontWeight={"500"} mb={1.5} variant="h6">
        이름: {userData.userName} / ({userData.gender === "MALE" ? "남" : "여"})
      </Typography>
      <Typography fontWeight={"500"} mb={1.5} variant="h6">
        이메일: {userData.email}
      </Typography>
      <Typography fontWeight={"500"} mb={1.5} variant="h6">
        연락처: {userData.phoneNumber}
      </Typography>
      <Typography fontWeight={"500"} mb={1.5} variant="h6">
        소개: {userData.introduction}
      </Typography>
      <Button
        variant={"contained"}
        mt={1.5}
        onClick={() => {
          deleteUserController(id);
        }}
      >
        탈퇴처리
      </Button>
    </Container>
  );
};

export default UserDetail;
