import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  bannerDeleteController,
  bannerUploadController,
} from "../../controller/BannerController";
import { posterListController } from "../../controller/PosterController";
import {
  popUpDeleteController,
  popUpUpdateController,
} from "../../controller/PopUpController";

const PopupDetail = () => {
  const { data: posterList } = useQuery({
    queryKey: ["poster_list"],
    queryFn: () => posterListController(0, 10, "ACTIVE"),
  });

  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const PopupData = JSON.parse(queryParams.get("data"));

  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");
  const [imageUrl, setImageUrl] = React.useState(
    "https://picsum.photos/200/300"
  );
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [preview, setPreview] = React.useState("");

  const [imgUrl, setImgUrl] = React.useState("");

  const [checkedId, setCheckedId] = React.useState();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log(event.target.files);

    // 이미지 미리보기 설정
    const reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post("YOUR_SERVER_ENDPOINT", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  React.useEffect(() => {
    console.log(selectedFile);
  }, [selectedFile]);

  React.useEffect(() => {
    if (PopupData) {
      setImageUrl(PopupData.imageUrl);
      setCheckedId(PopupData.posterId);
      setImgUrl(PopupData.imageUrl);
    }
  }, []);

  return (
    <Container>
      <Header />
      <Box
        mb={5}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight={"700"}>
          팝업 상세 / 수정 / 등록 페이지
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1.5,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              if (id && checkedId) {
                popUpUpdateController(
                  {
                    imageUrl: imageUrl,
                    posterId: checkedId,
                  },
                  id
                );
              } else {
                popUpUpdateController({
                  imageUrl: imageUrl,
                  posterId: checkedId,
                });
              }
            }}
          >
            등록 / 수정
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              popUpDeleteController(id);
              navigate(-1);
            }}
          >
            삭제
          </Button>
        </Box>
      </Box>

      <Typography variant="body1">이미지 선택</Typography>
      <input
        accept="image/*"
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="upload-button"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label htmlFor="upload-button">
          <Button
            variant="contained"
            component="span"
            sx={{
              marginBottom: 2,
            }}
          >
            Choose Image
          </Button>
        </label>
        {preview ||
          (imgUrl && (
            <img
              src={preview ? preview : imgUrl}
              alt="Preview"
              style={{ width: "300px", height: "300px" }}
            />
          ))}
      </Box>
      <Grid
        container
        py={1.5}
        px={1}
        sx={{
          backgroundColor: "silver",
        }}
      >
        <Grid item xl={1} md={1}>
          선택
        </Grid>
        <Grid item xl={5} md={5}>
          포스터 제목
        </Grid>
        <Grid item xl={4} md={4}>
          이벤트 이름
        </Grid>
        <Grid item xl={2} md={2}>
          장르
        </Grid>
      </Grid>
      {posterList &&
        posterList?.map((posterEl, index) => (
          <Grid
            container
            py={1.5}
            px={1}
            key={`${JSON.stringify(posterEl.posterId)}_${index}`}
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid silver",
              "&: hover": {
                backgroundColor: "gray",
              },
            }}
            onClick={() => {
              setCheckedId(posterEl.posterId);
            }}
          >
            <Grid
              item
              xl={1}
              md={1}
              sx={{
                diplay: "flex",
                // alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "15px",
                  height: "15px",
                  border:
                    checkedId === posterEl.posterId
                      ? "2px solid black"
                      : "1px solid silver",
                  backgroundColor:
                    checkedId === posterEl.posterId ? "red" : "#fff",
                }}
              />
            </Grid>
            <Grid item xl={5} md={5}>
              {posterEl.posterId} / {posterEl.title}
            </Grid>
            <Grid item xl={4} md={4}>
              {posterEl.eventName}
            </Grid>
            <Grid item xl={2} md={2}>
              {posterEl.genre}
            </Grid>
          </Grid>
        ))}
    </Container>
  );
};

export default PopupDetail;
