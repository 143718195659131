import axios from "axios";
import { getToken } from "../utils/localStorage";

export const sendAllMemberNotification = async (title, body) => {
  const endPoint = "/api/admins/notifications";
  const token = getToken();

  try {
    const response = await axios.post(
      endPoint,
      { title, body },
      {
        token,
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      console.log("Error Response Data:", error.response.data);
    } else {
      console.log("Error:", error.message);
    }
    throw error;
  }
};
