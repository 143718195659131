import axios from "axios";
import { getToken } from "../utils/localStorage";

export const getUserList = async () => {
  const endPoint = "/api/admins";

  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.result.users;
  } catch (error) {
    throw error;
  }
};

export const deleteUserController = async (id) => {
  const endPoint = `/api/admins/members/deactivate/${id}`;
  const token = getToken();

  try {
    const response = await axios.post(
      endPoint,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    alert(response.data.message);
    return response.data;
  } catch (error) {
    throw error;
  }
};
