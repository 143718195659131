import { Box, Container, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getUserList } from "../../controller/UserController";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";

const UserList = () => {
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["user_list"],
    queryFn: () => {
      return getUserList();
    },
  });

  React.useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Container>
      <Header />
      <Box mb={5} mt={5}>
        <Typography variant="h5" fontWeight={"700"}>
          유저 리스트 페이지
        </Typography>
      </Box>
      <Grid
        container
        py={1.5}
        px={1}
        sx={{
          backgroundColor: "silver",
        }}
      >
        <Grid item xl={2.5} md={2.5}>
          이름
        </Grid>
        <Grid item xl={3} md={3}>
          핸드폰번호
        </Grid>
        <Grid item xl={4} md={4}>
          이메일
        </Grid>
        <Grid item xl={2.5} md={2.5}>
          상태
        </Grid>
      </Grid>
      {data &&
        data?.map((userEl, index) => (
          <Grid
            container
            py={1.5}
            px={1}
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid silver",
              "&: hover": {
                backgroundColor: "gray",
              },
            }}
            onClick={() =>
              navigate(
                `/user_detail/${userEl.memberId}?data=${JSON.stringify(userEl)}`
              )
            }
          >
            <Grid item xl={2.5} md={2.5}>
              {userEl.userName}
            </Grid>
            <Grid item xl={3} md={3}>
              {userEl.phoneNumber}
            </Grid>
            <Grid item xl={4} md={4}>
              {userEl.email}
            </Grid>
            <Grid item xl={2.5} md={2.5}>
              {userEl.status}
            </Grid>
          </Grid>
        ))}
    </Container>
  );
};

export default UserList;
