import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isLoggedInState } from "../../recoil/loginStorage";
import Header from "../../components/Header";

const Home = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Header />
      <Grid container>
        <Grid item xl={3}>
          준비중
        </Grid>
        <Grid item xl={9}></Grid>
      </Grid>
    </Container>
  );
};

export default Home;
