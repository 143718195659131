import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { posterListController } from "../../controller/PosterController";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  categoryDeleteController,
  categoryUpdateController,
} from "../../controller/CategoryController";

const CategoryDetail = () => {
  const queryClient = useQueryClient();

  const { data: posterList } = useQuery({
    queryKey: ["poster_list"],
    queryFn: () => posterListController(0, 200, "ACTIVE"),
  });

  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const posterData = JSON.parse(queryParams.get("data"));

  const [title, setTitle] = React.useState("");

  const [checkedId, setCheckedId] = React.useState([]);

  React.useEffect(() => {
    if (posterData) {
      setTitle(posterData.categoryName);
      let temp = posterData.simplePosters.map((el) => {
        return el.posterId;
      });
      setCheckedId(temp);
    }
  }, []);

  return (
    <Container>
      <Header />
      <Box
        mb={5}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight={"700"}>
          카테고리 상세 / 수정 / 등록 페이지
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1.5,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              if (id && checkedId) {
                categoryUpdateController(
                  {
                    categoryName: title,
                    posterIds: checkedId,
                  },
                  id
                );
              } else {
                categoryUpdateController({
                  categoryName: title,
                  posterIds: checkedId,
                });
              }
              queryClient.invalidateQueries("category_list");
            }}
          >
            등록 / 수정
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              categoryDeleteController(id);
              queryClient.invalidateQueries("category_list");
              navigate(-1);
            }}
          >
            삭제
          </Button>
        </Box>
      </Box>
      <TextField
        variant="outlined"
        sx={{
          width: "100%",
          marginBottom: 1.5,
        }}
        InputLabelProps={{}}
        value={title}
        label="제목을 입력하세요"
        onChange={(e) => setTitle(e.target.value)}
      />
      <Grid
        container
        py={1.5}
        px={1}
        sx={{
          backgroundColor: "silver",
        }}
      >
        <Grid item xl={1} md={1}>
          선택
        </Grid>
        <Grid item xl={4} md={4}>
          이벤트 이름
        </Grid>
        <Grid item xl={5} md={5}>
          장소
        </Grid>
        <Grid item xl={2} md={2}>
          장르
        </Grid>
      </Grid>
      {posterList !== undefined &&
        posterList.posters &&
        posterList?.posters.map((posterEl, index) => (
          <Grid
            container
            py={1.5}
            px={1}
            key={`${JSON.stringify(posterEl.posterId)}_${index}`}
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid silver",
              "&: hover": {
                backgroundColor: "gray",
              },
            }}
            onClick={() => {
              let temp = checkedId ? [...checkedId] : [];
              if (!temp.find((el) => el === posterEl.posterId)) {
                temp.push(posterEl.posterId);
              } else {
                temp = temp.filter((el) => el !== posterEl.posterId);
              }
              setCheckedId(temp);
            }}
          >
            <Grid
              item
              xl={1}
              md={1}
              sx={{
                diplay: "flex",
                // alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "15px",
                  height: "15px",
                  border: checkedId.find((el) => el === posterEl.posterId)
                    ? "2px solid black"
                    : "1px solid silver",
                  backgroundColor: checkedId.find(
                    (el) => el === posterEl.posterId
                  )
                    ? "red"
                    : "#fff",
                }}
              />
            </Grid>
            <Grid item xl={4} md={4}>
              {posterEl.eventName}
            </Grid>
            <Grid item xl={5} md={5}>
              {posterEl.place.address}
            </Grid>
            <Grid item xl={2} md={2}>
              {posterEl.genre}
            </Grid>
          </Grid>
        ))}
    </Container>
  );
};

export default CategoryDetail;
