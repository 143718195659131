import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import { getAddressController } from "../../controller/AddressController";
import Pagination from "../Pagination";
const AddressSelectModal = ({
  address,
  setAddress,
  setIsOpen,
  setLongitude,
  setLatitude,
}) => {
  const [searchWord, setSearchWord] = React.useState("");
  const [totalPage, setTotalPage] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [addressList, setAddressList] = React.useState([]);

  const handlePageAddressCall = async () => {
    const response = await getAddressController(currentPage, searchWord);

    if (response.status === "OK") {
      setAddressList(response.result.items);
    } else {
      alert(response.status);
    }
  };

  const handleAddressCall = async () => {
    setCurrentPage(1);
    const response =
      searchWord.length > 0
        ? await getAddressController(1, searchWord)
        : alert("검색어를 입력하세요");

    if (response.status === "OK") {
      setTotalPage(response.page.total);
      setAddressList(response.result.items);
    } else {
      alert(response.status);
    }
  };

  return (
    <Box
      onClick={() => setIsOpen(false)}
      sx={{
        position: "fixed",
        width: "100vw",
        height: "100%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 100,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          paddingTop: 3,
          paddingBottom: 3,
          width: "70%",
          height: "70%",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 3,
            height: 10,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            sx={{ width: "70%" }}
            variant="outlined"
            value={searchWord}
            label="검색어 입력"
            onChange={(e) => setSearchWord(e.target.value)}
          />
          <Button
            sx={{ height: 40 }}
            variant="contained"
            onClick={handleAddressCall}
          >
            검색
          </Button>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{ overflow: "scroll", marginTop: 3 }}
        >
          {addressList !== undefined &&
            addressList.length > 0 &&
            addressList.map((el) => (
              <Box
                paddingTop={1.5}
                paddingBottom={1.5}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setAddress(el.address.road);
                  setLongitude(el.point.y);
                  setLatitude(el.point.x);
                  setIsOpen((prev) => !prev);
                }}
              >
                <Typography variant={"body2"}>{el.address.road}</Typography>
              </Box>
            ))}
        </Box>
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          onPageChange={handlePageAddressCall}
          setCurrentPage={setCurrentPage}
        />
      </Box>
    </Box>
  );
};

export default AddressSelectModal;
