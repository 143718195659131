import axios from "axios";
import { getToken } from "../utils/localStorage";

export const bannerListController = async () => {
  const endPoint = `/api/banners`;
  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.result.banners;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const bannerDetailController = async (id) => {
  const endPoint = `/api/banners/${id}`;

  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const bannerUploadController = async (data, id) => {
  const endPoint = id ? `/api/admins/banners/${id}` : "/api/admins/banners";

  const token = getToken();

  try {
    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    alert(response.data.message);
    return response.data;
  } catch (err) {
    alert(err);
    console.log(err.response);
    throw err;
  }
};

export const bannerDeleteController = async (id) => {
  const endPoint = `/api/admins/banners/${id}`;

  const token = getToken();

  try {
    const response = await axios.delete(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    alert("삭제되었습니다.");
    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
